import React, {Component} from 'react'
import './assets/css/main.css';
import axios from 'axios';

import logo from './assets/img/logo.png';
import question from './assets/img/question.png'
import chevron from './assets/img/chevron-right.png';

import Header from './Header';
import Main from './Main';
import SelectGroup from './SelectGroup';
import Select from './Select';
import Content from './Content';
import Table from './Table';
import Row from './Row';
import Loader from './Loader';

import naicsData from './assets/data/naics.json';

export default class extends Component {
  state = {
    thinking: false,
    naics: [
      [ "", "Select Industry" ]
    ],
    ranges: {
      ebitda: {
        min: "?",
        max: "?"
      },
      priceToRevenue: {
        min: "?",
        max: "?"
      }
    }
  }

  componentDidMount(){
    this.setState({
      naics: [
        ["", "Select Industry"],
        ...Object.keys(naicsData).map(record => [record, record])
      ]
    })
  }

  fetchNaics = e => {
    let record = naicsData[e.target.value];
    let ranges = {
      ranges: {
        ebitda: record["EBITDA"],
        priceToRevenue: record["Price to Revenue"],
      },
      thinking: false
    }

    this.setState({
      thinking: true
    })

    window.setTimeout(() => this.setState(ranges), 2500)
    
  }

  render() {
    return (
      <div className='mt-wrapper'>
        { this.state.thinking ? <Loader></Loader> : null }
        <Header>
          <img src={logo} alt="InfoVera"/>
        </Header>
        <Main>
          <div className='mt-constrain'>
            <h1>Find Multiples For Your Industry</h1>
            <p>Select your industry from the below list to learn the minimum and maximum multiples for your business at the industry level.</p>
            <SelectGroup>
              <Select name="industry" options={this.state.naics} onChange={this.fetchNaics}/>
              <Select name="sector" options={[["", "Sector"]]} disabled={true} />
              <Select name="subSector" options={[["", "Sub-Sector"]]} disabled={true} />
            </SelectGroup>            
          </div>
        </Main>
        <Content>
          <div className='mt-constrain'>
            <h2>Industry Multiples</h2>
            <p>Minimum and maximum multiples are provided below for the industry you selected. Multiples are used to determine the likely selling valuation of a business based on a variety of factors, such as the business’ EBITDA, Net Income, Price to Revenue and Assets. To provide a more accurate assessment, you can visit the <a href="https://portal.infovera.com">InfoVera</a> portal to provide more information, allowing us to fine-tune your assessment and provide the median values for your specific Industry, Sector and Sub-Sector.</p>
            <Table>
              <ul className='mt-ebitda-row mt-ebitda-row-header'>
                <li>Category</li>
                <li>Minimum</li>
                <li><strong>You</strong></li>
                <li>Maximum</li>
              </ul>
              <Row min={this.state.ranges.ebitda.min} max={this.state.ranges.ebitda.max} category="EBITDA"></Row>
              <Row min={this.state.ranges.priceToRevenue.min} max={this.state.ranges.priceToRevenue.max} category="Revenue"></Row>
            </Table>
            <h3>Ready to learn more about your business’ value?</h3>
            <a href="https://portal.infovera.com/" className='mt-cta-button'>
              Continue to the InfoVera Portal <img src={chevron} />
            </a>            
          </div>
        </Content>
      </div>
    )
  }
}
